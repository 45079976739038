import React from 'react';
import PreHeader from '../components/PreHeader'
import Header from '../components/Header'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import CTA from '../components/CTA'
import creative1 from '../images/creative-1.png'
export default () => (
    <div id="app">
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="Description" content="WRAL Digital Solutions Refresh Advertise With Us Page"></meta>
          <title>Creative Services | WRAL Digital Solutions</title>
          <link rel="canonical" href="https://www.wraldigitalsolutions.com/creative-services/" />
        </Helmet>
      <PreHeader/>
      <Header/>
      <h1 className="creative--title">Creative Services</h1>
      <div className="creative">
        <div className = "creative_wrapper">
          <img src={creative1} className="creative--image" alt="creative mobile mockups"/>
          <div className = "creative_grid">
            <div className= "creative_block">
              <h3 className="creative_block--title">Website Design</h3>
              <p className="creative_block--text">We have a dedicated team of designers, front-end, and back-end developers to bring your vision to life. We create fully customized and responsive sites with a comprehensive SEO strategy at launch. Our hosting solutions are fast, secure, and allow for automatic core and manual updates. </p>
            </div>
            <div className= "creative_block">
              <h3 className="creative_block--title">Copywriting</h3>
              <p className="creative_block--text">Our team of skilled writers craft messages that are both engaging and SEO-friendly. We work to identify topics that will boost your traffic and improve the performance of your advertising, regardless of the platform. We can help with content strategy, website copy, site map construction, branded content, script writing, and more. </p>
            </div>
            <div className= "creative_block">
              <h3 className="creative_block--title">Video Production</h3>
              <p className="creative_block--text videoprod">The power of video and video advertising continues to be on the rise. Let our creative team and producers create your next long-form or short-form video. To view a gallery of our work, visit our <a className="creative--vimeo" href="https://vimeo.com/user103521252" target="_blank" rel="noopener noreferrer">Vimeo </a>channel today. </p>
            </div>
            <div className= "creative_block">
              <h3 className="creative_block--title">Brand Identity and Strategy</h3>
              <p className="creative_block--text">Do you have an amazing product or service line that you’re not quite sure how to bring to life? Let us help you craft your story and lay the foundation for your marketing strategy, sales materials, and more. </p>
            </div>
            <div className= "creative_block">
              <h3 className="creative_block--title">Graphic Design</h3>
              <p className="creative_block--text">From logo design to landing page design, our experienced designers can help create visuals that are reflective of who you are as a business and a brand.</p>
            </div>
          </div>
        </div>

        <div className="creative_banner">
            <h3 className="creative_banner-text">
              To get started today <a href="tel:+1-919-744-3030">call</a> or fill out our contact form.
            </h3>
          <div className="creative_cta">
              <CTA text="Contact Us" type="button" link="/contact" classname="contact-us--mobile"/>
              <CTA text="Contact Us" type="button" link="/contact" classname="contact-us--desktop"/>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
